<template>
  <!-- 住院预缴 -->
  <div class="hospitalization-prepayment">
    <!-- 切换就诊人 -->
    <SwitchPeople />
    <div v-if="Object.keys(list).length != 0" class="information-card">
      <div class="card-top">
        <div class="large" style="font-weight:600;">
          <span>总押金</span>
          <span style="color:red">
            {{
              list.total_amt == undefined ||
              list.total_amt == null ||
              list.total_amt == ''
                ? '---'
                : list.total_amt + '元'
            }}
          </span>
        </div>
        <div>
          <span>姓名</span>
          <span style="color: #4b7af9">
            {{
              list.name == undefined || list.name == null || list.name == ''
                ? '---'
                : list.name
            }}
          </span>
        </div>
        <div>
          <span>性别</span>
          <span>
            {{
              list.sex == undefined || list.sex == null || list.sex == ''
                ? '---'
                : list.sex
            }}
          </span>
        </div>
        <div>
          <span>住院科室</span>
          <span>
            {{
              list.dept_name == undefined ||
              list.dept_name == null ||
              list.dept_name == ''
                ? '---'
                : list.dept_name
            }}
          </span>
        </div>
        <div>
          <span>病床</span>
          <span>
            {{
              list.bed_no == undefined ||
              list.bed_no == null ||
              list.bed_no == ''
                ? '---'
                : list.bed_no
            }}
          </span>
        </div>
        <div>
          <span>住院号</span>
          <span>
            {{
              list.admission_no == undefined ||
              list.admission_no == null ||
              list.admission_no == ''
                ? '---'
                : list.admission_no
            }}
          </span>
        </div>
        <div>
          <span>账户余额</span>
          <span>
            {{
              list.admi_balance == undefined ||
              list.admi_balance == null ||
              list.admi_balance == ''
                ? '---'
                : list.admi_balance
            }}
          </span>
        </div>
        <!-- <div>
          <span style="flex:0.3">入院天数</span>
          <span style="flex:0.3;white-space:nowrap;color: #8a8488;">{{`已入院${times}天`}}</span>
        </div> -->
      </div>
      <div class="card-bottom">
        <van-button round class="button" @click="show = !show">预缴</van-button>
      </div>
      <!-- 预缴页面 -->
      <van-action-sheet
        v-model="show"
        :round="false"
        close-on-popstate
        @close="value = ''"
        title="  "
        style="height:100vh;max-height:100%;white-space:pre;"
      >
        <span style="margin-left:1rem">充值金额</span>
        <van-field
          v-textVerification="value"
          v-model="value"
          label-width="2rem"
          type="number"
          label="￥"
          clearable
          class="text-border"
          :border="false"
        />
        <van-button
          plain
          round
          class="button"
          :disabled="rechargeStatus"
          @click="recharge(value)"
        >
          充值
        </van-button>
      </van-action-sheet>
    </div>
    <div v-else>
      <van-empty description="无住院预缴" />
    </div>
  </div>
</template>

<script>
import { qrcode, loopQuery } from '@/network/payment'
import SwitchPeople from '@/components/common/switchPeople/switchPeople'
import {
  saveTradeLog,
  updateLogFunTrade,
  inpatientIdcard,
} from '@/network/service'
import { times, Debounce } from '@/common/utils.js'
import { mapState } from 'vuex'
export default {
  name: 'hospitalizationPrepayment',
  data() {
    return {
      //展开板
      show: false,
      //输入金额
      value: '',
      //充值状态
      rechargeStatus: true,
      //住院信息
      list: {},
    }
  },
  components: {
    SwitchPeople,
  },
  computed: {
    ...mapState(['seq', 'cardList']),
    //住院时间
    times() {
      let time = new Date().getTime() - new Date(this.list.in_date).getTime()
      return Math.ceil(time / (1000 * 3600 * 24))
    },
  },
  mounted() {
    console.log(this.seq)
    this.inpatientIdcardPost()
  },
  watch: {
    //充值状态验证
    value: function(newVal) {
      if (
        newVal.indexOf('.') + 1 == newVal.length ||
        newVal.length == 0 ||
        Number(newVal) == 0
      ) {
        this.rechargeStatus = true
      } else {
        this.rechargeStatus = false
      }
    },
  },
  directives: {
    //金额验证
    textVerification: {
      update: (el, binding, vnode) => {
        let i = binding.value.split('')
        if (i[0] == '0' && i[1] >= 0) {
          vnode.context.value = binding.oldValue
        } else if (binding.oldValue.indexOf('.') == -1) {
          vnode.context.value = binding.value.match(/^\d*(\.?\d{0,2})/g)[0]
        } else if (
          binding.value.indexOf('.') + 1 == binding.value.length ||
          /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^(0){0}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(
            binding.value
          )
        ) {
          vnode.context.value = binding.value
        } else {
          vnode.context.value = binding.oldValue
        }
      },
    },
  },
  methods: {
    //住院信息
    async inpatientIdcardPost() {
      let res = await inpatientIdcard({
        med_id: this.cardList[0].cardNo,
        card_type: this.cardList[0].cardType,
      })
      if (res.code == '0') {
        this.list = res.data.root.body
      } else {
        this.$toast(res.msg)
      }
    },
    //点击充值
    recharge: Debounce(async function recharge() {
      let res = await qrcode({
        seq: this.seq,
        urlString: this.$urlString,
        appKey: '17b1a89be880001',
        appSecret: '33cb874e10bb409a84f71d29f49ca017',
        mchNo: 'lffbyy',
        billNo: this.cardList[0].openId + new Date().getTime(),
        accountId: '105000080629028',
        sceneId: 'TEST',
        totalAmount: parseInt(Number(this.value).toFixed(2) * 100),
        subject: '预缴款',
      })
      if (res.code == '0') {
        //保存支付总订单表日志记录
        await saveTradeLog({
          seq: this.seq,
          stat: 'PAYING',
          total_charge_amt: Number(this.value).toFixed(2),
          person_pay: Number(this.value).toFixed(2),
          channel: '建行聚合支付',
          trade_no: res.data.trade_no,
          med_pay: '1',
          business_type: '预缴款',
        })
        //更新流水号
        await updateLogFunTrade({
          seq: this.seq,
          finishFlag: '预缴款',
          card_type: this.cardList[0].cardType,
          card_no: this.cardList[0].cardNo,
          idno: this.cardList[0].idNo,
          name: this.cardList[0].name,
        })
        let date = times()
        //下单轮询
        let outcome = await loopQuery({
          seq: this.seq,
          urlString: this.$urlString,
          mchNo: 'lffbyy',
          appKey: '17b1a89be880001',
          appSecret: '33cb874e10bb409a84f71d29f49ca017',
          trade_no: res.data.trade_no,
          tradeNo: res.data.trade_no,
          admission_no: this.list.admission_no,
          recharge_amt: Number(this.value).toFixed(2),
          payway: '0',
          consme_date: date.split(' ')[0],
          consme_time: date.split(' ')[1],
          source: '预缴款',
          refundReason: '业务失败退还',
        })
        if (outcome.code == 0) {
          //调用下单页面
          window.location.href = res.data.codeUrl
        } else {
          this.$toast(res.msg)
        }
      } else {
        this.$toast(res.msg)
      }
    }, 1500),
  },
}
</script>

<style lang="less" scoped>
/deep/.van-button--normal {
  font-size: 1rem;
}
.button {
  color: rgb(255, 255, 255);
  font-size: 0.875rem;
}
.hospitalization-prepayment {
  background-color: @backgroundColor;
  height: 100vh;
  .information-card {
    width: 95vw;
    margin: 1rem auto;
    border-radius: 1rem;
    background-color: @cardColor;
    box-sizing: border-box;
    padding: 0 1rem;
    padding-top: 1rem;
    .card-top {
      line-height: 2rem;
      border-bottom: 0.0625rem solid @lineColor;
      padding-bottom: 0.5rem;
      div {
        display: flex;
        justify-content: space-between;
        span {
          flex: 0.5;
        }
        span:last-child {
          text-align: right;
        }
      }
    }
    .card-bottom {
      overflow: hidden;
      .van-button {
        float: right;
        text-align: right;
        background: @darkColor;
        border: 0.0625rem solid @darkColor;
        height: 2rem;
        width: 4rem;
      }
    }
    .text-border {
      font-size: 2rem;
      line-height: 3rem;
      border-top: 1px solid @lineColor;
      border-bottom: 1px solid @lineColor;
      margin-top: 1rem;
    }
    /deep/.van-button {
      display: block;
      margin: 1.5rem auto;
      width: 90%;
      border-color: @darkColor;
      background-color: @darkColor;
    }
  }
}
</style>
